<template>
  <div class="container">
    
    <img src="@/assets/mp_qr.jpg" class="happy">
    <h3 class="tips" >关注公众号获取更多问卷</h3>
    <template v-if="submitStatus === 'S'" >
      <h3 class="congratulations" >恭喜您</h3>
      <p class="desc">答题码：{{ extAnswerStr }} </p>
    </template>
    <p class="desc">{{submitStatusMap[submitStatus]}}</p>
  </div>
</template>
<script>
import { getParams } from '../utils/index'
export default {
  name: 'extResult',
  data () {
    return {
      extAnswerStr: '', // 外部推广答题码，用于前端展示
      answerIdentifier: '', // 答题令牌 暂时用不上
      submitStatus: '', // S-提交成功，D-被甄别，F-配额满
      submitStatusMap: {
        S: '已成功完成',
        D: '对不起，被甄别',
        F: '对不起，配额已满'
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.extAnswerStr = getParams('extAnswerStr')
      this.answerIdentifier = getParams('answerIdentifier')
      this.submitStatus = getParams('submitStatus')
    }
  }
}
</script>
<style scoped>
.container{
  text-align: center;
}
img.happy{
  width: 60%;
  margin-bottom: 6px;
  margin-top: 20px;
}
h3.tips{
  margin-bottom: 20px;
}
.desc{
  padding:  0 40px;
}

</style>